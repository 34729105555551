import { pushToast, useStoreData } from "@/redux/onboardingSlice";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { customTokenLoginThunk } from "../redux/onboardingThunks";
import { PAGES } from "@/constant/misc";


export default function Home(props) {
  const {token} = props.query
  const { loading, error, marketfeedUser } = useStoreData();
  const dispatch = useDispatch();
  const router = useRouter()
  // useEffect(() => {
  //   if(token)
  //   dispatch(customTokenLoginThunk(token));
  // }, [token])

  return (
    <>
      <h1>{loading ? "Logging you in" : error ? error  : marketfeedUser?.displayName}</h1>
    </>
  );
}
